import { ENV } from "../../../../src/app/interfaces/interfaces";

export const environment:ENV = {
    production: false,
    apiUrl: 'https://fipazbackend.test.solunes.com/',
    type: 'fipaz',
    title: 'Fipaz',
    subtitle: 'Tickets',
    description: `"Fipaz - Tickets" es un sitio en el que puedes comprar tus entradas para la feria`,
    keywords: 'Fipaz, tickets, entradas',
    yearProyect: '2024',
    imgLogo: '/assets/img/logo.png',
    imgLogoSVG: '/assets/img/logosvg-2.png',
    imgLogoBig: '/assets/img/logo-big.png',
    templateType: 'template-4',
    theme: 'light',
    changeTheme: false,

    dashboard: false,
    disclaimer: false,
    header: true,
    sidebar: true,
    footer: false,
    preFooter: false,

    cart: false,

    authType: 'auth-3',
    authGoogle: false,
    authFacebook: false,
    authApple: false,
    authGithub: false,
    textToLogin: '¿Ya tienes una cuenta?',
    textToRegister: '¿Todavía no eres miembro?',

    authRedirect: 'events',

    processCartType: 'type-1',
    processCartTitle: 'Conviértete en Socio Centenario',

    multiplePayments: true,
    multipleProfiles: true,

    loaderType: 1,
    loaderDefaultText: 'Cargando...',

    //* Firebase
    firebase: {
        apiKey: "AIzaSyBjEiddg6zcJcPzH90MY_I_QhvWa79BOtY",
        authDomain: "fexco-2024.firebaseapp.com",
        projectId: "fexco-2024",
        storageBucket: "fexco-2024.appspot.com",
        messagingSenderId: "187810682149",
        appId: "1:187810682149:web:5fddf99198ec2177bb36cc"
    },

    keyEncript: '3fa85f64c2f9e0c53fa85f64c2f9e0c5',
    ivEncript: '3fa85f64c2f9e0c53fa85f64c2f9e0c5',
};
